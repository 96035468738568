@import "~antd/dist/antd.css";

.ant-layout {
  min-height: 100vh;
}

.ant-layout-header {
  height: unset;
  padding: 16px 5.2vw;
  line-height: unset;
  background: #ffffff;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 900;
  box-shadow: 0 4px 1.25vw 0 rgb(6 8 40 / 8%);
}

p {
  margin-bottom: 0;
}

@media only screen and (max-width: 960px) {
  .ant-layout-footer {
    padding: 0 5.3vw;
    background: #2a2c30;
  }
  .ant-layout-content {
    background: #ffffff;
    padding-top: calc(32px + 7.07vw);
  }
}

@media only screen and (min-width: 961px) {
  .ant-layout-footer {
    padding: 0 20.83vw;
    background: #2a2c30;
  }
  .ant-layout-content {
    background: #ffffff;
    padding-top: calc(32px + 40px);
  }
}
